import './index.css';

import _ from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { logout } from '../../api/auth';
import defaultPortrait from '../../assets/images/banner/defaultPortrait.svg';
import AuthContext from '../../context/Auth/AuthContext';
import { LOGIN } from '../../routes/routePaths';
import { prefix, suffix } from '../../utils/breadcrumb';
import findCurrentRoute from '../../utils/route/findCurrentRoute';
import ActionList from '../ActionList';

function Navbar() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();
  const matchRoute = findCurrentRoute(location);

  const actions = [
    {
      text: t('login.logout'),
      onMouseDown: async () => {
        const [loginResp] = await logout();
        if (loginResp) {
          localStorage.removeItem('smrs_t');
          history.push(LOGIN);
        }
      },
    },
  ];

  return (
    <div className="navbar">
      <div className="flex-1 px-6 text-18 leading-6 font-semibold text-dark-grey">
        {`${prefix(matchRoute.name)} `}
        {t(`breadcrumb.${matchRoute.name}`)}
        {` ${suffix(matchRoute.name)}`}
      </div>
      <ActionList className="mx-6" actions={actions}>
        <div className="btn-navbar-user">
          <img
            className="h-8 w-8 object-cover rounded-full"
            src={
              !_.isEmpty(user.portraitUrl) ? user.portraitUrl : defaultPortrait
            }
            alt="Portrait"
          />
          <div className="flex flex-col items-start ml-3 text-dark-grey-300">
            <div className="flex items-center text-14 leading-4 font-semibold">
              {_.get(user, 'name', '')}
              <i className="gsif gsif-triangle text-blue ml-1 transform rotate-180 scale-50"></i>
            </div>
            <div className="text-12 leading-3 mt-1">
              {_.has(user, 'level') && t(`adminType.${user.level}`)}
            </div>
          </div>
        </div>
      </ActionList>
    </div>
  );
}

export default Navbar;
