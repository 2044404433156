import { gql } from '@apollo/client';
import { disableFragmentWarnings } from 'graphql-tag';

disableFragmentWarnings();

export const ProjectNameFragment = gql`
  fragment ProjectNameFragment on Project {
    id
    name
  }
`;

export const CustomSettingFragment = gql`
  fragment CustomSettingFragment on Project {
    id
    name
    logoImageUrl
    coverImageUrl
    memberCardImageUrl
    webTitle
    themeColor
    preferences {
      fillBirthday
      fillEmail
      fillName
      fillTel
      needOtp
    }
    policy
    contributionButtonLable
    contributionButtonUrl
    awardButtonLable
    awardButtonUrl
    webLang
    chatfuelSignupUrl
    chatfuelSignupSuccessUrl
    chatfuelApiToken
  }
`;

export const ProjectLevelsFragment = gql`
  fragment ProjectLevelsFragment on Project {
    id
    levels {
      title
      score
    }
  }
`;

export const ProjectApiFragment = gql`
  fragment ProjectApiFragment on Project {
    id
    credentials {
      id
      token
      createdAt
    }
    memberLoginUrl
  }
`;

export const StoreTagsFragment = gql`
  fragment StoreTagsFragment on Project {
    storeTags {
      id
      name
    }
  }
`;

export const UserTagsFragment = gql`
  fragment UserTagsFragment on Project {
    userTags {
      id
      name
    }
  }
`;

export const ProductTagsFragment = gql`
  fragment ProductTagsFragment on Project {
    productTags {
      id
      name
    }
  }
`;

export const ProjectTagsFragment = gql`
  fragment ProjectTagsFragment on Project {
    id
    ...StoreTagsFragment
    ...UserTagsFragment
    ...ProductTagsFragment
  }
  ${StoreTagsFragment}
  ${UserTagsFragment}
  ${ProductTagsFragment}
`;

export const PriceToPointFragment = gql`
  fragment PriceToPointFragment on PriceToPointSetting {
    rate
    ruleType
    tags {
      id
      name
      expiredAt
    }
    desc
  }
`;

export const TagSimpleFragment = gql`
  fragment TagSimpleFragment on Tag {
    id
    name
  }
`;
