import { getBaseUrl } from '../services/variables';

export const PROJECT_TITLE_WORD_LIMIT = 30;
export const PROJECT_DESCRIPTION_WORD_LIMIT = 100;

export const ORGANIZATION_TITLE_WORD_LIMIT = 30;

export const LOGO_MAX_SIZE = 5; // MB
export const LOGO_RESIZE_HEIGHT = 200; // px
export const BANNER_MAX_SIZE = 5; // MB
export const BANNER_SUGGEST_WIDTH = 1440; // px
export const BANNER_SUGGEST_HEIGHT = 720; // px

export const LEVEL_QUANTITY_LIMIT = 6;
export const LEVEL_TITLE_LIMIT = 15;

export const MISSION_TYPE_IMAGE_MAXSIZE = 5;
export const MISSION_TYPE_IMAGE_WIDTH = 720;
export const MISSION_TYPE_IMAGE_HEIGHT = 192;
export const MISSION_TYPE_NAME_LIMIT = 30;

export const MISSION_NAME_LIMIT = 30;

export const AWARD_IMAGE_MAXSIZE = 5;
export const AWARD_IMAGE_WIDTH = 300;
export const AWARD_IMAGE_HEIGHT = 480;
export const AWARD_NAME_LIMIT = 30;

export const COUPON_IMAGE_MAXSIZE = 5;
export const COUPON_IMAGE_WIDTH = 345;
export const COUPON_IMAGE_HEIGHT = 570;
// export const COUPON_NAME_LIMIT = 30;

export const TABLE_ITEMS_PER_PAGE = 10;

export const USER_LEVEL_ADMIN = 'admin';
export const USER_LEVEL_GOSKY_ADMIN = 'gosky_admin';

export const MISSION_POINT_RECORD_COMMENT_LIMIT = 100;

export const VALID_IMAGE_ATTR_NAME = [
  'logo_img',
  'cover_img',
  'award_img',
  'field_img',
];

export const USER_MANAGE_ITEMS_PER_PAGE = 10;

export const TAG_TYPE_STORE = 'STORE';
export const TAG_TYPE_USER = 'USER';
export const TAG_TYPE_PRODUCT = 'PRODUCT';
export const TAG_NAME_LIMIT = 6;

export const RECENT_ACTIVE_MAX_MONTHS = 36;
export const STORE_NAME_LIMIT = 15;

export const PRODUCT_NAME_LIMIT = 10;

export const RECORDS_TYPE_POS = 'pos';
export const RECORDS_TYPE_TASK = 'task';
export const RECORDS_TYPE_AWARD = 'award';
export const RECORDS_TYPE_COUPON = 'coupon';

export const PRICE_TO_POINT_DESC_WORD_LIMIT = 300;

export const COUPON_DETAILS_WORD_LIMIT = 300;
export const COUPON_NAME_LIMIT = 30;
export const COUPON_DESC_WORD_LIMIT = 30;

export const MAX_INTEGER = 21474835;

export const CONTRIBUTION_API_ENDPOINT = `${getBaseUrl()}/api/chatbot/v1/user/contribution_annal`;
export const AWARD_API_ENDPOINT = `${getBaseUrl()}/api/chatbot/v1/user/award_annal`;
