import './App.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import RouteProvider from './context/Route/RouteProvider';
import RouteGuard from './routes/RouteGuard';

function App() {
  return (
    <RouteProvider>
      <Router>
        <RouteGuard />
      </Router>
    </RouteProvider>
  );
}

export default App;
