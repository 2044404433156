import { gql } from '@apollo/client';
import { OrganizationFragment } from '../fragments/organization';
import {
  CustomSettingFragment,
  ProjectApiFragment,
  ProjectLevelsFragment,
  ProjectNameFragment,
  ProductTagsFragment,
  StoreTagsFragment,
  UserTagsFragment,
  PriceToPointFragment,
} from '../fragments/project';

export const GetProjectsQuery = gql`
  query GetProjectsQuery {
    projects {
      nodes {
        ...ProjectNameFragment
        organization {
          ...OrganizationFragment
        }
        updatedAt
        description
      }
    }
  }
  ${ProjectNameFragment}
  ${OrganizationFragment}
`;

export const GetProjectNamesQuery = gql`
  query GetProjectNamesQuery {
    projects {
      nodes {
        ...ProjectNameFragment
      }
    }
  }
  ${ProjectNameFragment}
`;

export const GetCustomSettingQuery = gql`
  query GetCustomSettingQuery($projectId: ID!) {
    project(id: $projectId) {
      ...CustomSettingFragment
    }
  }
  ${CustomSettingFragment}
`;

export const GetProjectLevelsQuery = gql`
  query GetProjectLevelsQuery($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectLevelsFragment
    }
  }
  ${ProjectLevelsFragment}
`;

export const GetProjectApiQuery = gql`
  query GetProjectApiQuery($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectApiFragment
    }
  }
  ${ProjectApiFragment}
`;

export const GetProjectTagsQuery = (type) => gql`
  query GetProjectTagsQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      ${!type || type === 'store' ? '...StoreTagsFragment' : ''}
      ${!type || type === 'user' ? '...UserTagsFragment' : ''}
      ${!type || type === 'product' ? '...ProductTagsFragment' : ''}
    }
  }
  ${!type || type === 'store' ? StoreTagsFragment : ''}
  ${!type || type === 'user' ? UserTagsFragment : ''}
  ${!type || type === 'product' ? ProductTagsFragment : ''}
`;

export const GetPriceToPointQuery = gql`
  query GetPriceToPointQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      priceToPointSetting {
        ...PriceToPointFragment
      }
    }
  }
  ${PriceToPointFragment}
`;
