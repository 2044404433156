import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

const ActionList = ({ actions = [], className = '', children }) => {
  const [isOpen, setOpen] = useState(false);
  const dropdownClass = classNames('dropdown', className, { open: isOpen });
  const dropdownButtonRef = useRef();

  const onButtonClick = () => {
    dropdownButtonRef.current.focus();
    setOpen(true);
  };

  return (
    <span className={dropdownClass}>
      {children ? (
        <button
          ref={dropdownButtonRef}
          className="btn-dropdown"
          onClick={onButtonClick}
          onBlur={() => setOpen(false)}
        >
          {children}
        </button>
      ) : (
        <button
          ref={dropdownButtonRef}
          className="btn-dropdown btn-icon"
          onClick={onButtonClick}
          onBlur={() => setOpen(false)}
        >
          <i className="gsif gsif-ellipsis-h" />
        </button>
      )}
      <span className="list-dropdown">
        {_.map(
          actions,
          (
            {
              className: itemClassName,
              text,
              prependIcon,
              onMouseDown,
              disabled,
            },
            index,
          ) => (
            <button
              key={`${text}-${index}`}
              className={`btn-dropdown-item btn text-left ${itemClassName}`}
              onMouseDown={onMouseDown}
              disabled={disabled}
            >
              {prependIcon && <i className={`gsif ${prependIcon}`} />}
              {prependIcon ? <span className="ml-2">{text}</span> : text}
            </button>
          ),
        )}
      </span>
    </span>
  );
};

export default ActionList;

ActionList.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      text: PropTypes.string,
      prependIcon: PropTypes.string,
      onMouseDown: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ),
  className: PropTypes.string,
  children: PropTypes.node,
};
