import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getGqlEndPoint } from '../variables';

const httpLink = createHttpLink({
  uri: getGqlEndPoint(),
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('smrs_t') || '';
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'gosky-api-key': token,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Project: {
        keyFields: ['id'],
        fields: {
          // Short for always preferring incoming over existing data.
          storeTags: { merge: false },
          userTags: { merge: false },
          productTags: { merge: false },
        },
      },
      Tag: { keyFields: ['id'] },
      Store: {
        keyFields: ['id'],
        fields: { tags: { merge: false } },
      },
      Organization: { keyFields: ['id'] },
      Deal: { keyFields: ['id'] },
      PrizeRecord: { keyFields: ['transactionId'] },
      CouponHistory: { keyFields: ['transactionId'] },
    },
  }),
});

export default client;
