export const ROOT = '/';
export const LOGIN = '/login';

export const PROJECT = '/project';
export const OVERVIEW = '/project/overview';
export const CURRENT_PROJECT = '/project/:id';

// 數據報告
export const DATA_REPORT = '/project/:projectId/data-report';

// 用戶管理
export const USER_MANAGE = '/project/:projectId/user-manage';

// 累積點數
export const TASK_GROUPS = '/project/:projectId/task-groups';
export const TASK_USER_RECORDS =
  '/project/:projectId/task-group/:taskGroupId/task/:taskId/users';
export const TASK_GROUP_USER_RECORDS =
  '/project/:projectId/task-group/:taskGroupId/users';
export const TASK_RECORDS =
  '/project/:projectId/task-group/:taskGroupId/task/:taskId/times';
export const TASK_GROUP_RECORDS =
  '/project/:projectId/task-group/:taskGroupId/times';

// 兌換點數
export const AWARDS = '/project/:projectId/awards';
export const AWARDS_USERS_RECORDS = '/project/:projectId/award/:awardId/users';
export const AWARDS_RECORDS = '/project/:projectId/award/:awardId/times';

// 實體商店
export const PRICE_TO_POINT = '/project/:projectId/price-to-point';
export const STORE_LIST = '/project/:projectId/store-list';
export const DEAL_RECORDS = '/project/:projectId/deal-records/:recordsType';
export const COUPON_LIST = '/project/:projectId/coupons';

// 設定
export const CUSTOM_SETTING = '/project/:projectId/custom-setting';
export const LEVEL_SETTING = '/project/:projectId/level-setting';
export const API_SETTING = '/project/:projectId/api-setting';

// 設定標籤
export const PRODUCT_SETTING = '/project/:projectId/product-setting';
export const TAG_MANAGEMENT = '/project/:projectId/tag-management';

// 優惠券
export const COUPON_DISPATCH_SETTING =
  '/project/:projectId/coupon-dispatch-setting/:dispatchType?';
